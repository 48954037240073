@charset "utf-8";

$cat1: "劇場公演";
$cat2: "仮面女子外部";
$cat3: "スチームガールズ外部";
$cat4: "撮影会";
$cat5: "メディア";
$cat6: "その他";

$pc:1025px;
$tab:1024px;
$sp:599px;

$noto: 'Noto Sans JP', serif;
$mont:  'Montserrat', 'Noto Sans JP', sans-serif;

$light:300;
$medium: 500;
$s-bold: 600;
$bold: 700;
$e-bold: 800;
$black: 900;

$bl: #101010;
$rd: #B40707;
$sc: #E10000;

$rs: #E74291;
$pk: #F19EC2;

@mixin pc {
  @media (min-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$baseWidth : 1600;
@function vw($pixelsVw) {
    @return $pixelsVw / $baseWidth * 100vw;
  }

@mixin fz9{
    display: block;
    font-size: 1rem;
    transform:scale(0.9);
    transform-origin: left center;
    width: calc(100% / .9);
}

