@charset "UTF-8";
/*　トップ　ここから　*/
.main--home {
  margin-top: 0;
}
.main--home .mark {
  display: inline;
  background: #FFD9D9;
}
.main--home .btn-arrow {
  width: 240px;
}
@media (max-width: 1024px) {
  .main--home .btn-arrow {
    width: 210px;
  }
}
.main--home .mv {
  margin-bottom: 60px;
}
.main--home .mv .slick-track {
  display: flex;
}
.main--home .mv__slide {
  height: auto !important;
  display: flex;
  align-items: center;
  background: #101010;
}
.main--home .mv img {
  width: 100%;
}
@media (max-width: 1024px) {
  .main--home .mv {
    margin-bottom: 40px;
  }
}
.main--home .mv .slick-dots {
  bottom: 18px;
}
.main--home .mv .slick-dots li {
  width: 40px;
  height: 3px;
}
.main--home .mv .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
  background: #D8D8D8;
}
.main--home .mv .slick-dots li button:before {
  display: none;
}
.main--home .mv .slick-dots li.slick-active button {
  background: #B40707;
}
@media (max-width: 1024px) {
  .main--home .mv .slick-dots {
    bottom: 8px;
  }
  .main--home .mv .slick-dots li {
    width: 30px;
  }
}
.main--home .intro {
  width: 800px;
  max-width: 90%;
  margin: 0 auto 100px;
  text-align: center;
}
.main--home .intro__lead {
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-size: 7.5rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  line-height: 1.2;
}
.main--home .intro__txt {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 2.125;
  margin-top: 57px;
}
.main--home .intro__txt:nth-of-type(n+3) {
  margin-top: 34px;
}
.main--home .intro__list {
  background: #fff;
  border: solid 1px #D8D8D8;
  padding: 15px 20px 15px;
  text-align: left;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 2;
  margin-top: 35px;
}
.main--home .intro__list .box {
  text-indent: -1.5rem;
  padding-left: 1.5rem;
}
@media (max-width: 1024px) {
  .main--home .intro {
    margin-bottom: 80px;
  }
  .main--home .intro__lead {
    font-size: 4rem;
  }
  .main--home .intro__txt {
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    line-height: 2.0714285714;
    margin-top: 36px;
  }
  .main--home .intro__txt:nth-of-type(n+3) {
    margin-top: 29px;
  }
  .main--home .intro__list {
    margin: 37px auto 0;
    width: 315px;
    letter-spacing: 0.1em;
    padding: 15px;
  }
}
.main--home .topics {
  background: #2a2a2a;
  padding: 42px 0 39px;
  margin-bottom: 100px;
}
.main--home .topics__ttl {
  margin-bottom: 26px;
  background-image: url(../img/common/ttl_img_wh.svg);
  color: #fff;
}
.main--home .topics__txt-wrap {
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
  color: #fff;
}
.main--home .topics__txt-wrap p {
  letter-spacing: 0.1em;
  line-height: 1.8571428571;
}
.main--home .topics__txt-wrap p + p {
  margin-top: 26px;
}
.main--home .topics__txt-wrap a {
  text-decoration: underline;
}
.main--home .topics__txt-wrap a:hover {
  text-decoration: none;
}
.main--home .topics__list {
  background: #fff;
  color: #101010;
  border: solid 1px #D8D8D8;
  padding: 15px 20px 15px;
  text-align: left;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 2;
  margin-top: 28px;
}
.main--home .topics__list .box {
  text-indent: -1.5rem;
  padding-left: 1.5rem;
}
.main--home .topics__list + p {
  margin-top: 25px;
}
@media (max-width: 1024px) {
  .main--home .topics {
    margin-bottom: 80px;
  }
  .main--home .topics__list {
    padding: 15px;
    margin-top: 17px;
  }
  .main--home .topics__list + p {
    margin-top: 16px;
  }
}
@media (max-width: 599px) {
  .main--home .topics__txt-wrap {
    width: 315px;
  }
}
.main--home .news,
.main--home .schedule {
  width: 800px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--home .news,
  .main--home .schedule {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--home .news,
  .main--home .schedule {
    width: 315px;
  }
}
.main--home .news__ttl,
.main--home .schedule__ttl {
  margin-bottom: 27px;
}
@media (max-width: 1024px) {
  .main--home .news__ttl,
  .main--home .schedule__ttl {
    margin-bottom: 30px;
  }
}
.main--home .news .front-list__item,
.main--home .schedule .front-list__item {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 8px;
}
.main--home .news .front-list__item:nth-of-type(n+2),
.main--home .schedule .front-list__item:nth-of-type(n+2) {
  margin-top: 18px;
}
.main--home .news .front-list .info,
.main--home .schedule .front-list .info {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.main--home .news .front-list .date,
.main--home .schedule .front-list .date {
  margin: 0;
}
.main--home .news .front-list .cat-row,
.main--home .schedule .front-list .cat-row {
  margin: 0 0 0 8px;
}
.main--home .news .front-list .cat,
.main--home .schedule .front-list .cat {
  margin: 2px 5px;
}
.main--home .news .front-list .ttl,
.main--home .schedule .front-list .ttl {
  line-height: 1.8571428571;
  letter-spacing: 0.1em;
}
.main--home .news .front-list__link:hover,
.main--home .schedule .front-list__link:hover {
  text-decoration: underline;
}
@media (max-width: 1024px) {
  .main--home .news .front-list__item,
  .main--home .schedule .front-list__item {
    padding-bottom: 2px;
  }
  .main--home .news .front-list__item:nth-of-type(n+2),
  .main--home .schedule .front-list__item:nth-of-type(n+2) {
    margin-top: 21px;
  }
  .main--home .news .front-list .info,
  .main--home .schedule .front-list .info {
    display: block;
  }
  .main--home .news .front-list .date,
  .main--home .schedule .front-list .date {
    margin-bottom: 11px;
  }
  .main--home .news .front-list .cat-row,
  .main--home .schedule .front-list .cat-row {
    margin-left: -5px;
  }
}
.main--home .news .btn-row,
.main--home .schedule .btn-row {
  text-align: center;
  margin-top: 30px;
}
.main--home .news--event .mc-list {
  list-style: none;
}
.main--home .news--event .heading,
.main--home .news--event .event-date {
  display: none !important;
}
.main--home .news--event .mc-events:nth-of-type(n+2) {
  margin-top: 18px;
}
.main--home .news--event .list-event {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 8px;
}
.main--home .news--event .list-event:nth-of-type(n+2) {
  margin-top: 18px;
}
.main--home .news--event .info {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.main--home .news--event .date {
  margin: 0;
}
.main--home .news--event .cat-row {
  margin: 0 0 0 8px;
}
.main--home .news--event .cat {
  margin: 2px 5px;
}
.main--home .news--event .cat__txt:after {
  display: block;
}
.main--home .news--event .cat__txt.theater:after {
  content: "劇場公演";
}
.main--home .news--event .cat__txt.kamenjoshi_gaibu:after {
  content: "仮面女子外部";
}
.main--home .news--event .cat__txt.steamgirls_gaibu:after {
  content: "スチームガールズ外部";
}
.main--home .news--event .cat__txt.photo_session:after {
  content: "撮影会";
}
.main--home .news--event .cat__txt.media:after {
  content: "メディア";
}
.main--home .news--event .cat__txt.other:after {
  content: "その他";
}
.main--home .news--event .ttl {
  line-height: 1.8571428571;
  letter-spacing: 0.1em;
}
@media (max-width: 1024px) {
  .main--home .news--event .mc-events:nth-of-type(n+2) {
    margin-top: 21px;
  }
  .main--home .news--event .list-event {
    padding-bottom: 2px;
  }
  .main--home .news--event .list-event:nth-of-type(n+2) {
    margin-top: 21px;
  }
  .main--home .news--event .info {
    display: block;
  }
  .main--home .news--event .date {
    margin-bottom: 11px;
  }
  .main--home .news--event .cat-row {
    margin-left: -5px;
  }
}
.main--home .movie {
  margin-bottom: 120px;
}
@media (max-width: 1024px) {
  .main--home .movie {
    margin-bottom: 100px;
  }
}
.main--home .movie__ttl {
  margin-bottom: 30px;
}
.main--home .movie__slider {
  margin-bottom: 19px;
}
.main--home .movie__slider .slick-prev,
.main--home .movie__slider .slick-next {
  width: 50px;
  height: 50px;
  border: solid 1px #D8D8D8;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 4px);
  z-index: 99;
  transition: 0.3s ease;
}
.main--home .movie__slider .slick-prev:before,
.main--home .movie__slider .slick-next:before {
  content: "";
  display: block;
  position: absolute;
  width: 11px;
  height: 11px;
  border-top: solid 2px #101010;
  border-right: solid 2px #101010;
  opacity: 1;
  top: 50%;
  transition: 0.3s ease;
}
.main--home .movie__slider .slick-prev:hover,
.main--home .movie__slider .slick-next:hover {
  background: #D8D8D8;
}
.main--home .movie__slider .slick-prev:hover:before,
.main--home .movie__slider .slick-next:hover:before {
  border-color: #fff;
}
.main--home .movie__slider .slick-prev {
  left: 16%;
}
.main--home .movie__slider .slick-prev:before {
  transform: translateY(-50%) rotate(-135deg);
  left: 22px;
}
.main--home .movie__slider .slick-next {
  right: 16%;
}
.main--home .movie__slider .slick-next:before {
  transform: translateY(-50%) rotate(45deg);
  right: 22px;
}
@media (max-width: 1024px) {
  .main--home .movie__slider {
    margin-bottom: 15px;
  }
  .main--home .movie__slider .slick-prev,
  .main--home .movie__slider .slick-next {
    width: 30px;
    height: 30px;
    top: calc(50% - 7px);
  }
  .main--home .movie__slider .slick-prev:before,
  .main--home .movie__slider .slick-next:before {
    width: 6px;
    height: 6px;
  }
  .main--home .movie__slider .slick-prev {
    left: 3%;
  }
  .main--home .movie__slider .slick-prev:before {
    left: 12px;
  }
  .main--home .movie__slider .slick-next {
    right: 3%;
  }
  .main--home .movie__slider .slick-next:before {
    right: 12px;
  }
}
.main--home .movie__slide {
  margin: 0 15px;
  padding-bottom: 15px;
}
.main--home .movie__slide .iframe-wrap {
  box-shadow: 8px 3px 12px rgba(0, 0, 0, 0.16);
}
@media (max-width: 1024px) {
  .main--home .movie__slide {
    margin: 0 5px;
  }
}
.main--home .movie .btn-row {
  text-align: center;
}

/*　トップ　ここまで　*/
/*　NEWS　ここから　*/
.main--news .news {
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .main--news .news {
    margin-bottom: 82px;
  }
}
.main--news .news__list {
  width: 966px;
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .main--news .news__list {
    max-width: 100%;
  }
}
.main--news .news__item .img-wrap {
  padding-bottom: 75%;
}
@media (max-width: 1024px) {
  .main--news .news__item .img-wrap {
    padding-bottom: 100%;
  }
}
@media (max-width: 1024px) {
  .main--news .news .date {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .main--news .news .cat-row {
    margin-bottom: 3px;
  }
  .main--news .news .cat__link {
    font-size: 1rem;
    padding: 3px 2px;
  }
}

.main--news .under-ttl {
  margin-bottom: 64px;
}
@media (max-width: 1024px) {
  .main--news .under-ttl {
    margin-bottom: 51px;
  }
}

.main--news-detail .under-ttl {
  margin-bottom: 63px;
}
@media (max-width: 1024px) {
  .main--news-detail .under-ttl {
    margin-bottom: 52px;
  }
}
.main--news-detail .detail {
  width: 720px;
  max-width: 90%;
  margin: 0 auto 106px;
}
@media (max-width: 599px) {
  .main--news-detail .detail {
    width: 315px;
    margin-bottom: 89px;
  }
}
.main--news-detail .detail__info {
  display: flex;
  margin-bottom: 25px;
}
.main--news-detail .detail__info .date {
  line-height: 22px;
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  margin: 0 10px 0 0;
}
.main--news-detail .detail__info .cat-row {
  margin: 0;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__info {
    margin-bottom: 19px;
  }
  .main--news-detail .detail__info .date {
    margin-right: 5px;
  }
}
.main--news-detail .detail__ttl {
  font-size: 2.7rem;
  letter-spacing: 0.1em;
  line-height: 1.4814814815;
  font-weight: bold;
  margin-bottom: 27px;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__ttl {
    font-size: 2.1rem;
    line-height: 1.4761904762;
    margin-bottom: 21px;
  }
}
.main--news-detail .detail__content {
  font-size: 1.4rem;
  line-height: 1.9285714286;
  letter-spacing: 0.1em;
  margin-bottom: 26px;
}
.main--news-detail .detail__content p + p {
  margin-top: 2rem;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__content {
    margin-bottom: 19px;
  }
}
.main--news-detail .detail__img-wrap {
  text-align: center;
}
.main--news-detail .detail__btn-row {
  margin-top: 60px;
  text-align: center;
  position: relative;
  padding: 0 85px;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__btn-row {
    margin-top: 51px;
  }
}
.main--news-detail .detail__btn-row .btn {
  color: #101010;
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-weight: 500;
}
.main--news-detail .detail__btn-row .btn--top {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  text-decoration: underline;
}
.main--news-detail .detail__btn-row .btn--top:hover {
  text-decoration: none;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__btn-row .btn--top {
    font-size: 1.4rem;
  }
}
.main--news-detail .detail__btn-row .btn--prev, .main--news-detail .detail__btn-row .btn--next {
  color: #101010;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.025em;
  position: absolute;
}
.main--news-detail .detail__btn-row .btn--prev:before, .main--news-detail .detail__btn-row .btn--prev:after, .main--news-detail .detail__btn-row .btn--next:before, .main--news-detail .detail__btn-row .btn--next:after {
  content: "";
  display: block;
  position: absolute;
  transition: 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
}
.main--news-detail .detail__btn-row .btn--prev:before, .main--news-detail .detail__btn-row .btn--next:before {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 1px #D8D8D8;
}
.main--news-detail .detail__btn-row .btn--prev:after, .main--news-detail .detail__btn-row .btn--next:after {
  width: 10px;
  height: 10px;
  border-top: solid 2px #101010;
  border-right: solid 2px #101010;
}
.main--news-detail .detail__btn-row .btn--prev:hover:before, .main--news-detail .detail__btn-row .btn--next:hover:before {
  background: #D8D8D8;
}
.main--news-detail .detail__btn-row .btn--prev:hover:after, .main--news-detail .detail__btn-row .btn--next:hover:after {
  border-color: #fff;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__btn-row .btn--prev, .main--news-detail .detail__btn-row .btn--next {
    font-size: 1.2rem;
  }
  .main--news-detail .detail__btn-row .btn--prev:before, .main--news-detail .detail__btn-row .btn--next:before {
    width: 28px;
    height: 28px;
  }
}
.main--news-detail .detail__btn-row .btn--prev {
  left: 0;
  padding-left: 46px;
}
.main--news-detail .detail__btn-row .btn--prev:before {
  left: 0;
}
.main--news-detail .detail__btn-row .btn--prev:after {
  transform: translateY(-50%) rotate(-135deg);
  left: 14px;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__btn-row .btn--prev {
    padding-left: 40px;
  }
}
.main--news-detail .detail__btn-row .btn--next {
  right: 0;
  padding-right: 46px;
}
.main--news-detail .detail__btn-row .btn--next:before {
  right: 0;
}
.main--news-detail .detail__btn-row .btn--next:after {
  transform: translateY(-50%) rotate(45deg);
  right: 14px;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__btn-row .btn--next {
    padding-right: 40px;
  }
}
.main--news-detail .detail .mc_edit_links {
  display: none !important;
}

/*　NEWS　ここまで　*/
/*　SCHEDULE　ここから　*/
.main--schedule {
  padding-bottom: 100px;
}
.main--schedule .under-ttl {
  margin-bottom: 69px;
}
@media (max-width: 1024px) {
  .main--schedule .under-ttl {
    margin-bottom: 56px;
  }
}
.main--schedule #calendar {
  width: 1080px;
  max-width: 90%;
  margin: 0 auto;
}
.main--schedule .daily {
  width: 1080px;
  max-width: 90%;
  margin: 60px auto 0;
}
.main--schedule .daily__ttl {
  text-align: center;
  font-size: 3rem;
}
.main--schedule .daily__list {
  margin-top: 30px;
}
.main--schedule .daily__box {
  background: #D8D8D8;
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #fff;
}
.main--schedule .daily__box:nth-of-type(n+2) {
  margin-top: 20px;
}
.main--schedule .daily__box .info {
  width: 160px;
  padding-right: 10px;
  text-align: center;
}
@media (max-width: 1024px) {
  .main--schedule .daily__box {
    padding: 10px;
  }
  .main--schedule .daily__box:nth-of-type(n+2) {
    margin-top: 10px;
  }
  .main--schedule .daily__box .info {
    width: 100px;
    padding-right: 10px;
    font-size: 1.2rem;
  }
}
.main--schedule .daily__box .cat:after {
  display: block;
  text-align: center;
  margin-top: 5px;
  padding: 5px 5px;
  border-radius: 4px;
}
.main--schedule .daily__box .cat.theater:after {
  content: "劇場公演";
  background: #FF4093;
}
.main--schedule .daily__box .cat.kamenjoshi_gaibu:after {
  content: "仮面女子外部";
  background: #9151F2;
}
.main--schedule .daily__box .cat.steamgirls_gaibu:after {
  content: "スチームガールズ外部";
  background: #31A7E3;
}
.main--schedule .daily__box .cat.photo_session:after {
  content: "撮影会";
  background: #5FCE8B;
}
.main--schedule .daily__box .cat.media:after {
  content: "メディア";
  background: #C6C954;
}
.main--schedule .daily__box .cat.other:after {
  content: "その他";
  background: #FF973D;
}
.main--schedule .daily__box .trigger {
  font-size: 1.8rem;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s ease;
  color: #fff;
}
.main--schedule .daily__box .trigger:hover {
  color: #B40707;
}
@media (max-width: 1024px) {
  .main--schedule .daily__box .trigger {
    font-size: 1.4rem;
  }
}

/*　SCHEDULE　ここまで　*/
/*　THEATER　ここから　*/
.main--theater {
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .main--theater {
    margin-bottom: 80px;
  }
}
.main--theater .under-ttl {
  margin-bottom: 63px;
}
@media (max-width: 1024px) {
  .main--theater .under-ttl {
    margin-bottom: 53px;
  }
}
.main--theater .mv {
  width: 900px;
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 599px) {
  .main--theater .mv {
    width: 315px;
  }
}
.main--theater .banner {
  width: 900px;
  max-width: 90%;
  margin: 20px auto 0;
}
@media (min-width: 1025px) {
  .main--theater .banner.col2 {
    display: flex;
    justify-content: center;
  }
  .main--theater .banner.col2 .banner__box {
    margin: 0 15px;
  }
}
@media (max-width: 1024px) {
  .main--theater .banner {
    margin-top: 24px;
    text-align: center;
  }
  .main--theater .banner.col2 .banner__box {
    display: inline-block;
  }
  .main--theater .banner.col2 .banner__box + .banner__box {
    margin-top: 24px;
  }
}
@media (max-width: 599px) {
  .main--theater .banner {
    width: 315px;
  }
}
.main--theater .tab {
  margin-top: 55px;
}
@media (max-width: 1024px) {
  .main--theater .tab {
    margin-top: 52px;
  }
}
.main--theater .tab__btns {
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #D8D8D8;
}
.main--theater .tab__btn {
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  padding: 10px 30px 20px;
  cursor: pointer;
  position: relative;
}
.main--theater .tab__btn.select:after {
  content: "";
  display: block;
  position: absolute;
  width: 94px;
  height: 8px;
  background: #B40707;
  bottom: -4px;
  left: calc(50% - 47px);
}
@media (max-width: 1024px) {
  .main--theater .tab__btn {
    font-size: 1.8rem;
    padding: 10px 15px 20px;
  }
  .main--theater .tab__btn.select:after {
    width: 60px;
    left: calc(50% - 30px);
  }
}
.main--theater .tab__content {
  width: 900px;
  max-width: 90%;
  margin: 0 auto;
  display: none;
}
.main--theater .tab__content.show {
  display: block;
}
@media (max-width: 599px) {
  .main--theater .tab__content {
    width: 315px;
  }
}
.main--theater .anchor-list {
  width: 900px;
  padding-left: 20px;
  max-width: 100%;
  margin: 49px auto 61px;
  display: flex;
  flex-wrap: wrap;
}
.main--theater .anchor-list__item {
  width: 32%;
  margin: 0 2% 7px 0;
}
.main--theater .anchor-list__item:nth-of-type(3n) {
  margin-right: 0;
}
@media (max-width: 1024px) {
  .main--theater .anchor-list {
    justify-content: space-between;
    margin-bottom: 45px;
    padding-left: 0;
  }
  .main--theater .anchor-list__item {
    width: 49%;
    margin-right: 0;
  }
}
@media (max-width: 599px) {
  .main--theater .anchor-list__item {
    width: 100%;
  }
}
.main--theater section {
  word-break: break-word;
}
.main--theater section + section {
  margin-top: 100px;
}
@media (max-width: 1024px) {
  .main--theater section + section {
    margin-top: 82px;
  }
}
.main--theater section .medium {
  font-weight: 500;
}
.main--theater section .fz18 {
  font-size: 1.8rem;
  vertical-align: baseline;
}
.main--theater section .indent {
  padding-left: 1.5rem;
  text-indent: -1.5rem;
}
.main--theater section h2 + p {
  margin-top: 27px;
}
.main--theater section h2 + h3 {
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .main--theater section h2 + p {
    margin-top: 22px;
  }
}
.main--theater section h3 {
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.4761904762;
  padding-left: 30px;
  position: relative;
}
.main--theater section h3:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 17px;
  height: 22px;
  background: url(../img/theater/ic_kamen.svg) center/contain no-repeat;
}
.main--theater section h3 + p {
  margin-top: 10px;
}
.main--theater section h3 + ul {
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .main--theater section h3 {
    font-size: 1.8rem;
    line-height: 1.5;
    padding-left: 27px;
  }
  .main--theater section h3:before {
    width: 15px;
    height: 20px;
  }
}
.main--theater section p {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 1.8571428571;
}
.main--theater section p + p {
  margin-top: 25px;
}
.main--theater section p + h3 {
  margin-top: 47px;
}
.main--theater section p + ul {
  margin-top: 10px;
}
.main--theater section p + .time__row {
  margin-top: 25px;
}
@media (max-width: 1024px) {
  .main--theater section p + h3 {
    margin-top: 37px;
  }
}
.main--theater section a:not(.btn-arrow) {
  color: #8A8A8A;
  text-decoration: underline;
}
.main--theater section a:not(.btn-arrow).blank:after {
  content: url(../img/common/ic_blank_gy.svg);
  display: inline-block;
  padding-left: 5px;
}
.main--theater section .btn-arrow {
  width: 320px;
}
.main--theater section ul {
  border: solid 1px #D8D8D8;
  padding: 15px 20px 15px;
  text-align: left;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 2;
}
.main--theater section ul li {
  text-indent: -1.5rem;
  padding-left: 1.5rem;
}
.main--theater section ul li.img-wrap {
  text-align: center;
  margin: 5px 0 10px;
}
.main--theater section ul li a:not(.btn-arrow).blank:after {
  padding-left: 20px;
}
.main--theater section ul + h3 {
  margin-top: 50px;
}
.main--theater section ul + p {
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .main--theater section ul + h3 {
    margin-top: 40px;
  }
}
.main--theater section .scroll-box {
  margin-top: 30px;
  border: solid 1px #8A8A8A;
  height: 340px;
  overflow-y: scroll;
  padding: 19px 18px;
}
@media (max-width: 1024px) {
  .main--theater section .scroll-box {
    margin-top: 25px;
  }
}
.main--theater section .theater-360 {
  margin-top: 45px;
}
@media (max-width: 1024px) {
  .main--theater section .theater-360 {
    margin-top: 37px;
  }
}
.main--theater section .theater-slider {
  margin-top: 12px;
}
.main--theater section .theater-slider .slide {
  text-align: center;
}
.main--theater section .theater-slider a {
  display: inline-block;
  margin: 0 15px;
}
@media (max-width: 1024px) {
  .main--theater section .theater-slider a {
    margin: 0 6px;
  }
}
.main--theater section .theater-slider .slick-prev,
.main--theater section .theater-slider .slick-next {
  width: 50px;
  height: 50px;
  border: solid 1px #D8D8D8;
  background: #fff;
  border-radius: 50%;
  top: calc(50% + 8px);
  z-index: 99;
  transition: 0.3s ease;
}
.main--theater section .theater-slider .slick-prev:before,
.main--theater section .theater-slider .slick-next:before {
  content: "";
  display: block;
  position: absolute;
  width: 11px;
  height: 11px;
  border-top: solid 2px #101010;
  border-right: solid 2px #101010;
  opacity: 1;
  top: 50%;
  transition: 0.3s ease;
}
.main--theater section .theater-slider .slick-prev:hover,
.main--theater section .theater-slider .slick-next:hover {
  background: #D8D8D8;
}
.main--theater section .theater-slider .slick-prev:hover:before,
.main--theater section .theater-slider .slick-next:hover:before {
  border-color: #fff;
}
.main--theater section .theater-slider .slick-prev {
  left: 20%;
}
.main--theater section .theater-slider .slick-prev:before {
  transform: translateY(-50%) rotate(-135deg);
  left: 22px;
}
.main--theater section .theater-slider .slick-next {
  right: 20%;
}
.main--theater section .theater-slider .slick-next:before {
  transform: translateY(-50%) rotate(45deg);
  right: 22px;
}
@media (max-width: 1024px) {
  .main--theater section .theater-slider .slick-prev,
  .main--theater section .theater-slider .slick-next {
    width: 30px;
    height: 30px;
    top: calc(50% - 7px);
  }
  .main--theater section .theater-slider .slick-prev:before,
  .main--theater section .theater-slider .slick-next:before {
    width: 6px;
    height: 6px;
  }
  .main--theater section .theater-slider .slick-prev {
    left: 3%;
  }
  .main--theater section .theater-slider .slick-prev:before {
    left: 12px;
  }
  .main--theater section .theater-slider .slick-next {
    right: 3%;
  }
  .main--theater section .theater-slider .slick-next:before {
    right: 12px;
  }
}
.main--theater section .theater-slider + p {
  margin-top: 10px;
}
.main--theater section .time__row {
  margin-top: 10px;
  display: flex;
}
.main--theater section .time__row + ul {
  margin-top: 8px;
}
.main--theater section .time__row + h3 {
  margin-top: 50px;
}
.main--theater section .time__box {
  width: 23%;
  margin-right: 2.66666%;
}
.main--theater section .time__box:nth-of-type(4n) {
  margin-right: 0;
}
.main--theater section .time__box p {
  font-weight: 500;
}
.main--theater section .time__ttl {
  text-align: center;
  border: solid 1px #101010;
  line-height: 1;
  padding: 4px 0;
  margin-bottom: 11px;
}
.main--theater section .time__ttl.bl {
  background: #101010;
  color: #fff;
}
@media (max-width: 1024px) {
  .main--theater section .time__row {
    display: block;
  }
  .main--theater section .time__box {
    width: 100%;
    max-width: 200px;
    margin-right: 0;
    white-space: nowrap;
  }
  .main--theater section .time__box + .time__box {
    margin-top: 26px;
  }
}
.main--theater section .enter__row {
  margin-top: 31px;
  display: flex;
  justify-content: space-between;
}
.main--theater section .enter__row + p {
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .main--theater section .enter__row {
    display: block;
    text-align: center;
    margin-top: 24px;
  }
  .main--theater section .enter__box + .enter__box {
    margin-top: 13px;
  }
}
.main--theater section .map {
  width: 600px;
  max-width: 100%;
  margin: 20px auto 0;
}
.main--theater section .map .iframe-wrap {
  padding-bottom: 450px;
}
.main--theater section .map + h3 {
  margin-top: 60px;
}
@media (max-width: 1024px) {
  .main--theater section .map .iframe-wrap {
    padding-bottom: 300px;
  }
}
.main--theater section .calendar {
  margin-top: 48px;
}
.main--theater section .performer__row {
  width: 650px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.main--theater section .performer__box {
  margin: 12px 6px 0;
}
@media (max-width: 1024px) {
  .main--theater section .performer__box {
    width: calc(46% - 12px);
    text-align: center;
  }
  .main--theater section .performer__box.col1 {
    width: calc(100% - 12px);
  }
  .main--theater section .performer__box.col2 {
    width: calc(58% - 12px);
  }
}

/*　THEATER　ここまで　*/
/*　MUSIC　ここから　*/
.main--music {
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .main--music {
    margin-bottom: 80px;
  }
}
.main--music .under-ttl {
  margin-bottom: 63px;
}
@media (max-width: 1024px) {
  .main--music .under-ttl {
    margin-bottom: 53px;
  }
}
.main--music .anchor-list {
  width: 800px;
  max-width: 100%;
  margin: 0 auto 54px;
  display: flex;
  flex-wrap: wrap;
}
.main--music .anchor-list__item {
  width: 32%;
  margin: 0 2% 7px 0;
}
.main--music .anchor-list__item:nth-of-type(3n) {
  margin-right: 0;
}
@media (max-width: 1024px) {
  .main--music .anchor-list {
    justify-content: space-between;
    margin-bottom: 45px;
  }
  .main--music .anchor-list__item {
    width: 49%;
    margin-right: 0;
  }
}
@media (max-width: 599px) {
  .main--music .anchor-list__item {
    width: 100%;
  }
}
.main--music .music {
  width: 1080px;
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 599px) {
  .main--music .music {
    width: 315px;
  }
}
.main--music .music .group:nth-of-type(n+2) {
  margin-top: 80px;
}
.main--music .music .group__ttl {
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .main--music .music .group__ttl {
    margin-bottom: 25px;
  }
}
.main--music .music__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
@media (max-width: 1024px) {
  .main--music .music__list {
    display: block;
    margin: 0 -3px;
  }
}
.main--music .music__item {
  width: 31%;
  text-align: center;
  margin: 0 3.5% 20px 0;
}
.main--music .music__item:nth-of-type(3n) {
  margin-right: 0;
}
.main--music .music__item .img-wrap {
  margin-bottom: 3px;
  padding: 0 3px 6px;
}
.main--music .music__item .img-wrap img {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.main--music .music__item .ttl {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.5;
}
.main--music .music__item .ttl:after {
  content: url(../img/common/ic_blank_bl.svg);
  display: inline-block;
  padding-left: 5px;
}
@media (max-width: 1024px) {
  .main--music .music__item {
    width: 100%;
    margin: 0;
  }
  .main--music .music__item:nth-of-type(n+2) {
    margin-top: 20px;
  }
  .main--music .music__item .img-wrap {
    margin-bottom: 2px;
  }
  .main--music .music__item .ttl {
    font-size: 1.4rem;
    line-height: 1.4285714286;
  }
}
.main--music .music__link {
  display: block;
}
.main--music .music__link:hover .ttl {
  text-decoration: underline;
}
.main--music .btn-row {
  margin-top: 40px;
  text-align: center;
}
@media (max-width: 1024px) {
  .main--music .btn-row {
    margin-top: 59px;
  }
}

/*　MUSIC　ここまで　*/
/*　SNS / MOVIE　ここから　*/
.main--sns_movie {
  margin-bottom: 120px;
}
@media (max-width: 1024px) {
  .main--sns_movie {
    margin-bottom: 80px;
  }
}
.main--sns_movie .under-ttl {
  margin-bottom: 73px;
}
@media (max-width: 1024px) {
  .main--sns_movie .under-ttl {
    margin-bottom: 53px;
  }
}
.main--sns_movie .sns {
  width: 560px;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1025px) {
  .main--sns_movie .sns__box {
    width: 80px;
    margin-right: calc((100% - 400px) / 4);
  }
  .main--sns_movie .sns__box:nth-of-type(5n) {
    margin-right: 0;
  }
  .main--sns_movie .sns__box:nth-of-type(n+6) {
    margin-top: 40px;
  }
}
@media (max-width: 1024px) {
  .main--sns_movie .sns__box {
    width: 60px;
    margin-right: calc((100% - 240px) / 3);
  }
  .main--sns_movie .sns__box:nth-of-type(4n) {
    margin-right: 0;
  }
  .main--sns_movie .sns__box:nth-of-type(n+5) {
    margin-top: 40px;
  }
}

/*　SNS / MOVIE　ここまで　*/
/*　CONTACT　ここから　*/
.form-wrap .confirm-txt {
  display: none;
}
.form-wrap .btn-row {
  margin-top: 40px;
  text-align: center;
}
.form-wrap .btn-row .btn-wrap {
  max-width: 100%;
  display: inline-block;
}
.form-wrap .btn-row .btn {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-family: "Noto Sans JP", serif;
  font-weight: 500;
  color: #fff;
  background: #B40707;
  border-radius: 100px;
  line-height: 1.375;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
  width: 320px;
}
.form-wrap .btn-row .btn:hover {
  background: #E10000;
  padding-left: 30px;
}
.form-wrap .btn-row .btn:hover .arrow {
  right: 10px;
}
.form-wrap .btn-row .btn--back {
  background: #fff;
  border: solid 1px #8A8A8A;
  color: #101010;
}
.form-wrap .btn-row .btn--back:hover {
  background: #8A8A8A;
  color: #fff;
}
@media (max-width: 1024px) {
  .form-wrap .btn-row .btn {
    width: 300px;
  }
}

.main--contact .under-ttl {
  margin-bottom: 75px;
}
@media (max-width: 1024px) {
  .main--contact .under-ttl {
    margin-bottom: 54px;
  }
}
.main--contact .form-wrap {
  width: 700px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--contact .form-wrap {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--contact .form-wrap {
    width: 315px;
  }
}
.main--contact .form-wrap input,
.main--contact .form-wrap textarea,
.main--contact .form-wrap select {
  font-family: "Noto Sans JP", serif;
  border: solid 1px #D8D8D8;
  box-sizing: border-box;
  padding-left: 1rem;
  outline: none;
  border-radius: 8px;
}
.main--contact .form-wrap input::placeholder,
.main--contact .form-wrap textarea::placeholder,
.main--contact .form-wrap select::placeholder {
  color: #C8C8C8;
  font-size: 1.4rem;
  font-weight: 300;
}
.main--contact .form-wrap input[type=text],
.main--contact .form-wrap input[type=email] {
  height: 50px;
  width: 100%;
}
.main--contact .form-wrap textarea {
  width: 100%;
  height: 230px;
  padding: 1rem;
  resize: none;
}
.main--contact .form-wrap .select-wrap {
  position: relative;
  width: 47%;
}
.main--contact .form-wrap .select-wrap:before {
  content: "";
  display: block;
  position: absolute;
  right: 14px;
  top: calc(50% - 6px);
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  border-bottom: solid 2px #101010;
  border-right: solid 2px #101010;
  pointer-events: none;
}
.main--contact .form-wrap .select-wrap select {
  width: 100%;
  height: 50px;
  cursor: pointer;
  -webkit-appearance: none; /* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none; /* 標準のスタイルを無効にする */
  font-size: 1.5rem;
  font-weight: normal;
}
.main--contact .form-wrap .select-wrap ::-ms-expand {
  display: none;
}
@media (max-width: 1024px) {
  .main--contact .form-wrap .select-wrap {
    width: 100%;
  }
}
.main--contact .form-wrap input[type=submit],
.main--contact .form-wrap input[type=button] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}
.main--contact .form-wrap input[type=submit]::-webkit-search-decoration,
.main--contact .form-wrap input[type=button]::-webkit-search-decoration {
  display: none;
}
.main--contact .form-wrap input[type=submit]::focus,
.main--contact .form-wrap input[type=button]::focus {
  outline-offset: -2px;
}
.main--contact .form-wrap .dl + .dl {
  margin-top: 42px;
}
.main--contact .form-wrap .dl .dt {
  padding-bottom: 12px;
}
.main--contact .form-wrap .dl .dt__txt {
  font-size: 1.6rem;
  font-weight: medium;
  vertical-align: middle;
}
.main--contact .form-wrap .dl .dt .required {
  display: inline-block;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  padding: 4px 10px;
  margin-left: 8px;
  background: #B40707;
  vertical-align: middle;
}
.main--contact .form-wrap .dl .dd {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main--contact .form-wrap .dl .input-wrap {
  width: 100%;
}
.main--contact .form-wrap .dl .input-wrap--half {
  width: 48%;
}
.main--contact .form-wrap .dl .input-wrap--row2 + .input-wrap--row2 {
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .main--contact .form-wrap .dl + .dl {
    margin-top: 42px;
  }
  .main--contact .form-wrap .dl .dt {
    padding-bottom: 14px;
  }
}
.main--contact .form-wrap .btn-row {
  margin-top: 30px;
}
@media (max-width: 1024px) {
  .main--contact .form-wrap .btn-row {
    margin-top: 30px;
  }
}

.main--confirm .under-ttl {
  margin-bottom: 63px;
}
@media (max-width: 1024px) {
  .main--confirm .under-ttl {
    margin-bottom: 52px;
  }
}
.main--confirm .confirm {
  width: 900px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--confirm .confirm {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--confirm .confirm {
    width: 315px;
  }
}
.main--confirm .confirm__ttl {
  margin-bottom: 28px;
}
@media (max-width: 1024px) {
  .main--confirm .confirm__ttl {
    margin-bottom: 24px;
  }
}
.main--confirm .form-wrap .dl {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  line-height: 1.75;
}
.main--confirm .form-wrap .dl + .dl {
  margin-top: 26px;
}
.main--confirm .form-wrap .dl .dt {
  width: 22%;
}
.main--confirm .form-wrap .dl .dt__txt {
  font-weight: bold;
}
.main--confirm .form-wrap .dl .dt .required {
  display: none;
}
.main--confirm .form-wrap .dl .dd {
  width: 78%;
}
.main--confirm .form-wrap .dl .input-wrap--row2 + .input-wrap--row2 {
  display: none;
}
@media (max-width: 1024px) {
  .main--confirm .form-wrap .dl {
    display: block;
  }
  .main--confirm .form-wrap .dl .dt {
    width: 100%;
    padding-bottom: 4px;
  }
  .main--confirm .form-wrap .dl .dd {
    width: 100%;
  }
}
.main--confirm .form-wrap .confirm-txt {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75;
  margin: 47px 0;
}
.main--confirm .form-wrap .btn-row {
  margin-top: 0;
}
.main--confirm .form-wrap .btn-row .btn-wrap {
  width: 200px;
  margin: 0 20px;
}
.main--confirm .form-wrap .btn-row .btn-wrap--confirm {
  width: 0;
  margin: 0;
}
.main--confirm .form-wrap .btn-row .btn {
  padding: 19px 5px;
  width: 200px;
}
@media (max-width: 1024px) {
  .main--confirm .form-wrap .btn-row {
    display: flex;
    justify-content: center;
  }
  .main--confirm .form-wrap .btn-row .btn-wrap {
    margin: 0;
    max-width: 47%;
  }
  .main--confirm .form-wrap .btn-row .btn-wrap--back {
    margin-right: 6%;
  }
  .main--confirm .form-wrap .btn-row .btn {
    width: 150px;
    max-width: 100%;
  }
}

.main--completion .under-ttl {
  margin-bottom: 63px;
}
@media (max-width: 1024px) {
  .main--completion .under-ttl {
    margin-bottom: 52px;
  }
}
.main--completion .completion {
  width: 900px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--completion .completion {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--completion .completion {
    width: 315px;
  }
}
.main--completion .completion__ttl {
  margin-bottom: 28px;
}
@media (max-width: 1024px) {
  .main--completion .completion__ttl {
    margin-bottom: 24px;
  }
}
.main--completion .completion__txt-wrap {
  text-align: center;
  margin-bottom: 48px;
}
.main--completion .completion__txt-wrap .txt {
  font-size: 1.6rem;
  line-height: 1.75;
}
.main--completion .completion__txt-wrap .txt + .txt {
  margin-top: 28px;
}
@media (max-width: 1024px) {
  .main--completion .completion__txt-wrap {
    margin-bottom: 38px;
  }
}
.main--completion .completion__btn-row {
  text-align: center;
}
.main--completion .completion__btn-row .btn-arrow {
  width: 240px;
  font-family: "Noto Sans JP", serif;
}
@media (max-width: 1024px) {
  .main--completion .completion__btn-row .btn-arrow {
    width: 210px;
  }
}

/*　CONTACT　ここまで　*/