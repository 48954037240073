@use 'module' as mdl;


/*　トップ　ここから　*/

.main--home{
	margin-top: 0;
	.mark{
		display: inline;
		background: #FFD9D9;
	}
	.btn-arrow{
		width: 240px;
		@include mdl.tab{
			width: 210px;
		}
	}
	.mv{
		margin-bottom: 60px;
		.slick-track{
			display: flex;
		}
		&__slide{
			height: auto !important;
			display: flex;
			align-items: center;
			background: mdl.$bl;
		}
		img{
			width: 100%;
		}
		@include mdl.tab{
			margin-bottom: 40px;
		}
		.slick-dots{
			bottom:18px;
			li{
				width: 40px;
				height: 3px;
				button{
					width: 100%;
					height: 100%;
					padding: 0;
					background: #D8D8D8;
					&:before{
						display: none;
					}
				}
				&.slick-active{
					button{
						background: mdl.$rd;
					}
				}
			}
			@include mdl.tab{
				bottom:8px;
				li{
					width: 30px;
				}
			}
		}
	}
	.intro{
		width: 800px;
		max-width:90%;
		margin: 0 auto 100px;
		text-align: center;
		&__lead{
			font-family: mdl.$mont;
			font-size: 7.5rem;
			font-weight: bold;
			letter-spacing: 0.03em;
			line-height: calc(90 / 75);
		}
		&__txt{
			font-size: 1.6rem;
			letter-spacing: 0.05em;
			line-height: calc(34 / 16);
			margin-top: 57px;
			&:nth-of-type(n+3){
				margin-top: 34px;
			}
		}
		&__list{
			background: #fff;
			border: solid 1px #D8D8D8;
			padding: 15px 20px 15px;
			text-align: left;
			font-size: 1.4rem;
			letter-spacing: 0.05em;
			line-height: 2;
			margin-top: 35px;
			.box{
				text-indent: -1.5rem;
				padding-left: 1.5rem;
			}
		}
		@include mdl.tab{
			margin-bottom: 80px;
			&__lead{
				font-size: 4rem;
			}
			&__txt{
				font-size: 1.4rem;
				letter-spacing: 0.1em;
				line-height: calc(29 / 14);
				margin-top: 36px;
				&:nth-of-type(n+3){
					margin-top: 29px;
				}
			}
			&__list{
				margin: 37px auto 0;
				width: 315px;
				letter-spacing: 0.1em;
				padding: 15px;
			}
		}
	}
	.topics{
		background: #2a2a2a;
		padding: 42px 0 39px;
		margin-bottom: 100px;
		&__ttl{
			margin-bottom: 26px;
			background-image: url(../img/common/ttl_img_wh.svg);
			color: #fff;
		}
		&__txt-wrap{
			width: 800px;
			max-width:90%;
			margin: 0 auto;
			color: #fff;
			p{
				letter-spacing: 0.1em;
				line-height: calc(26 / 14);
				&+p{
					margin-top: 26px;
				}
			}
			a{
				text-decoration:underline;
				&:hover{
					text-decoration:none;
				}
			}
		}
		&__list{
			background: #fff;
			color: mdl.$bl;
			border: solid 1px #D8D8D8;
			padding: 15px 20px 15px;
			text-align: left;
			font-size: 1.4rem;
			letter-spacing: 0.1em;
			line-height: 2;
			margin-top: 28px;
			.box{
				text-indent: -1.5rem;
				padding-left: 1.5rem;
			}
			&+p{
				margin-top: 25px;
			}
		}
		@include mdl.tab{
			margin-bottom: 80px;
			&__list{
				padding: 15px;
				margin-top: 17px;
				&+p{
					margin-top: 16px;
				}
			}
		}
		@include mdl.sp{
			&__txt-wrap{
				width: 315px;
			}
		}
	}
	.news,
	.schedule{
		width: 800px;
		max-width: 90%;
		margin: 0 auto 100px;
		@include mdl.tab{
			margin-bottom: 80px;
		}
		@include mdl.sp{
			width: 315px;
		}
		&__ttl{
			margin-bottom: 27px;
			@include mdl.tab{
				margin-bottom: 30px;
			}
		}
		.front-list{
			&__item{
				border-bottom: 1px solid #D8D8D8;
				padding-bottom: 8px;
				&:nth-of-type(n+2){
					margin-top: 18px;
				}
			}
			.info{
				display: flex;
				align-items: center;
				margin-bottom: 7px;
			}
			.date{
				margin: 0;
			}
			.cat-row{
				margin: 0 0 0 8px;
			}
			.cat{
				margin: 2px 5px;
			}
			.ttl{
				line-height: calc(26 / 14);
				letter-spacing: 0.1em;
			}
			&__link{
				&:hover{
					text-decoration:underline;
				}
			}
			@include mdl.tab{
				&__item{
					padding-bottom: 2px;
					&:nth-of-type(n+2){
						margin-top: 21px;
					}
				}
				.info{
					display: block;
				}
				.date{
					margin-bottom: 11px;
				}
				.cat-row{
					margin-left: -5px;
				}
			}
		}
		.btn-row{
			text-align: center;
			margin-top: 30px;
		}
	}
	.news--event{
		.mc-list{
			list-style:none;
		}
		.heading,
		.event-date{
			display: none !important;
		}
		.mc-events{
			&:nth-of-type(n+2){
				margin-top: 18px;
			}
		}
		.list-event{
			border-bottom: 1px solid #D8D8D8;
			padding-bottom: 8px;
			&:nth-of-type(n+2){
				margin-top: 18px;
			}
		}
		.info{
			display: flex;
			align-items: center;
			margin-bottom: 7px;
		}
		.date{
			margin: 0;
		}
		.cat-row{
			margin: 0 0 0 8px;
		}
		.cat{
			margin: 2px 5px;
			&__txt{
				&:after{
					display: block;
				}
				&.theater{
					&:after{
						content: mdl.$cat1;
					}
				}
				&.kamenjoshi_gaibu{
					&:after{
						content: mdl.$cat2;
					}
				}
				&.steamgirls_gaibu{
					&:after{
						content: mdl.$cat3;
					}
				}
				&.photo_session{
					&:after{
						content: mdl.$cat4;
					}
				}
				&.media{
					&:after{
						content: mdl.$cat5;
					}
				}
				&.other{
					&:after{
						content: mdl.$cat6;
					}
				}
			}
		}
		.ttl{
			line-height: calc(26 / 14);
			letter-spacing: 0.1em;
		}
		@include mdl.tab{
			.mc-events{
				&:nth-of-type(n+2){
					margin-top: 21px;
				}
			}
			.list-event{
				padding-bottom: 2px;
				&:nth-of-type(n+2){
					margin-top: 21px;
				}
			}
			.info{
				display: block;
			}
			.date{
				margin-bottom: 11px;
			}
			.cat-row{
				margin-left: -5px;
			}
		}
	}
	.movie{
		margin-bottom: 120px;
		@include mdl.tab{
			margin-bottom: 100px;
		}
		&__ttl{
			margin-bottom: 30px;
		}
		&__slider{
			margin-bottom: 19px;
	  	.slick-prev,
	  	.slick-next{
	  		width: 50px;
	  		height: 50px;
	  		border: solid 1px #D8D8D8;
	  		background: #fff;
	  		border-radius: 50%;
	  		top:calc(50% - 4px);
	  		z-index: 99;
	  		transition: .3s ease;
	  		&:before{
	  			content:"";
	  			display: block;
	  			position: absolute;
	  			width: 11px;
	  			height: 11px;
	  			border-top: solid 2px mdl.$bl;
	  			border-right: solid 2px mdl.$bl;
	  			opacity: 1;
	  			top:50%;
	  			transition: .3s ease;
	  		}
	  		&:hover{
	  			background: #D8D8D8;
	  			&:before{
	  				border-color: #fff;
	  			}
	  		}
	  	}
	  	.slick-prev{
	  		left:16%;
	  		&:before{
	  			transform: translateY(-50%) rotate(-135deg);
	  			left:22px;
	  		}
	  	}
	  	.slick-next{
	  		right:16%;
	  		&:before{
	  			transform: translateY(-50%) rotate(45deg);
	  			right:22px;
	  		}
	  	}
	  	@include mdl.tab{
	  		margin-bottom: 15px;
	  		.slick-prev,
	  		.slick-next{
	  			width: 30px;
	  			height: 30px;
	  			top:calc(50% - 7px);
	  			&:before{
	  				width: 6px;
	  				height: 6px;
	  			}
	  		}
	  		.slick-prev{
	  			left:3%;
	  			&:before{
	  				left:12px;
	  			}
	  		}
	  		.slick-next{
	  			right:3%;
	  			&:before{
	  				right:12px;
	  			}
	  		}
	  	}
		}
		&__slide{
			margin: 0 15px;
			padding-bottom: 15px;
			.iframe-wrap{
				box-shadow: 8px 3px 12px rgba(0,0,0,.16);
			}
			@include mdl.tab{
				margin: 0 5px;
			}
		}
		.btn-row{
			text-align: center;
		}
	}
}

/*　トップ　ここまで　*/


/*　NEWS　ここから　*/

.main--news{
	.news{
		margin-bottom: 100px;
		@include mdl.tab{
			margin-bottom: 82px;
		}
		&__list{
		  width: 966px;
		  max-width: 90%;
		  margin: 0 auto;
		  @include mdl.tab{
		    max-width:100%;
		  }
		}
		&__item{
			.img-wrap{
				padding-bottom: 75%;
			}
			@include mdl.tab{
				.img-wrap{
					padding-bottom: 100%;
				}
			}
		}
		@include mdl.tab{
			.date{
				font-size: 1.2rem;
				margin-bottom: 10px;
			}
			.cat-row{
				margin-bottom: 3px;
			}
			.cat{
				&__link{
					font-size: 1rem;
					padding: 3px 2px;
				}
			}
		}
	}
}

.main--news{
	.under-ttl{
		margin-bottom: 64px;
		@include mdl.tab{
			margin-bottom: 51px;
		}
	}
}

.main--news-detail{
	.under-ttl{
		margin-bottom: 63px;
		@include mdl.tab{
			margin-bottom: 52px;
		}
	}
	.detail{
		width: 720px;
		max-width:90%;
		margin: 0 auto 106px;
	  @include mdl.sp{
	    width: 315px;
	    margin-bottom: 89px;
	  }
		&__info{
			display: flex;
			margin-bottom: 25px;
			.date{
				line-height: 22px;
				font-family: mdl.$mont;
				margin: 0 10px 0 0;
			}
			.cat-row{
				margin: 0;
			}
			@include mdl.tab{
				margin-bottom: 19px;
				.date{
					margin-right: 5px;
				}
			}
		}
		&__ttl{
			font-size: 2.7rem;
			letter-spacing: 0.1em;
			line-height: calc(40 / 27);
			font-weight: bold;
			margin-bottom: 27px;
			@include mdl.tab{
				font-size: 2.1rem;
				line-height: calc(31 / 21);
				margin-bottom: 21px;
			}
		}
		&__content{
			font-size: 1.4rem;
			line-height: calc(27 / 14);
			letter-spacing: 0.1em;
			margin-bottom: 26px;
			p+p{
				margin-top: 2rem;
			}
			@include mdl.tab{
				margin-bottom: 19px;
			}
		}
		&__img-wrap{
			text-align: center;
		}
		&__btn-row{
			margin-top: 60px;
			text-align: center;
			position: relative;
			padding: 0 85px;
			@include mdl.tab{
				margin-top: 51px;
			}
			.btn{
				color: mdl.$bl;
				font-family: mdl.$mont;
				font-weight: mdl.$medium;
				&--top{
					font-size: 1.6rem;
					letter-spacing: 0.1em;
					text-decoration:underline;
					&:hover{
						text-decoration:none;
					}
					@include mdl.tab{
						font-size: 1.4rem;
					}
				}
				&--prev,
				&--next{
					color: mdl.$bl;
					font-size: 1.3rem;
					font-weight: mdl.$medium;
					letter-spacing: 0.025em;
	        position: absolute;
		      &:before,
		      &:after{
	          content:"";
	          display: block;
	          position: absolute;
	          transition: .3s ease;
	          top:50%;
	          transform: translateY(-50%);
		      }
          &:before{
          	width: 32px;
          	height: 32px;
          	border-radius:50%;
		        border: solid 1px #D8D8D8;
          }
	        &:after{
	          width: 10px;
	          height: 10px;
	          border-top: solid 2px mdl.$bl;
	          border-right: solid 2px mdl.$bl;
	        }
	        &:hover{
	          &:before{
	          	background:#D8D8D8;
	          }
	          &:after{
	            border-color:#fff;
	          }
	        }
	        @include mdl.tab{
	        	font-size: 1.2rem;
	        	&:before{
	        		width: 28px;
	        		height: 28px;
	        	}
	        }
				}
	      &--prev{
	      	left:0;
	      	padding-left: 46px;
	      	&:before{
	      		left:0;
	      	}
	        &:after{
	          transform: translateY(-50%) rotate(-135deg);
	          left: 14px;
	        }
	        @include mdl.tab{
	        	padding-left: 40px;
	        }
	      }
	      &--next{
	      	right:0;
	      	padding-right: 46px;
	      	&:before{
	      		right:0;
	      	}
	        &:after{
	          transform: translateY(-50%) rotate(45deg);
	          right: 14px;
	        }
	        @include mdl.tab{
	        	padding-right: 40px;
	        }
	      }
			}
		}
		.mc_edit_links{
			display: none !important;
		}
	}
}

/*　NEWS　ここまで　*/


/*　SCHEDULE　ここから　*/

.main--schedule{
	padding-bottom: 100px;
	.under-ttl{
		margin-bottom: 69px;
		@include mdl.tab{
			margin-bottom: 56px;
		}
	}
	#calendar{
		width: 1080px;
		max-width:90%;
		margin: 0 auto;
	}
	.daily{
		width: 1080px;
		max-width:90%;
		margin: 60px auto 0;
		&__ttl{
			text-align: center;
			font-size: 3rem;
		}
		&__list{
			margin-top: 30px;
		}
		&__box{
			background: #D8D8D8;
			padding: 20px 30px;
			border-radius: 10px;
			display: flex;
			align-items: center;
			color: #fff;
			&:nth-of-type(n+2){
				margin-top: 20px;
			}
			.info{
				width: 160px;
				padding-right: 10px;
				text-align: center;
			}
			@include mdl.tab{
				padding: 10px;
				&:nth-of-type(n+2){
					margin-top: 10px;
				}
				.info{
					width: 100px;
					padding-right: 10px;
					font-size: 1.2rem;
				}
			}
			.cat{
				&:after{
					display: block;
					text-align: center;
					margin-top: 5px;
					padding: 5px 5px;
					border-radius:4px;
				}
				&.theater{
					&:after{
						content: mdl.$cat1;
						background: #FF4093;
					}
				}
				&.kamenjoshi_gaibu{
					&:after{
						content: mdl.$cat2;
						background: #9151F2;
					}
				}
				&.steamgirls_gaibu{
					&:after{
						content: mdl.$cat3;
						background: #31A7E3;
					}
				}
				&.photo_session{
					&:after{
						content: mdl.$cat4;
						background: #5FCE8B;
					}
				}
				&.media{
					&:after{
						content: mdl.$cat5;
						background: #C6C954;
					}
				}
				&.other{
					&:after{
						content: mdl.$cat6;
						background: #FF973D;
					}
				}
			}
			.trigger{
				font-size: 1.8rem;
				display: inline-block;
				cursor:pointer;
				transition:.3s ease;
				color: #fff;
				&:hover{
					color: mdl.$rd;
				}
				@include mdl.tab{
					font-size: 1.4rem;
				}
			}
		}
	}
}

/*　SCHEDULE　ここまで　*/

/*　THEATER　ここから　*/

.main--theater{
	margin-bottom: 100px;
	@include mdl.tab{
		margin-bottom: 80px;
	}
	.under-ttl{
		margin-bottom: 63px;
		@include mdl.tab{
			margin-bottom: 53px;
		}
	}
	.mv{
		width: 900px;
		max-width:90%;
		margin: 0 auto;
		@include mdl.sp{
			width: 315px;
		}
	}
	.banner{
		width: 900px;
		max-width:90%;
		margin: 20px auto 0;
		@include mdl.pc{
			&.col2{
				display: flex;
				justify-content: center;
				.banner__box{
					margin: 0 15px;
				}
			}
		}
		@include mdl.tab{
			margin-top: 24px;
			text-align: center;
			&.col2{
				.banner__box{
					display: inline-block;
					&+.banner__box{
						margin-top: 24px;
					}
				}
			}
		}
		@include mdl.sp{
			width: 315px;
		}
	}
	.tab{
		margin-top: 55px;
		@include mdl.tab{
			margin-top: 52px;
		}
		&__btns{
			display: flex;
			justify-content: center;
			border-bottom: solid 1px #D8D8D8;
		}
		&__btn{
			font-size: 2.4rem;
			font-weight: bold;
			letter-spacing: 0.1em;
			text-align: center;
			padding: 10px 30px 20px;
			cursor: pointer;
			position: relative;
			&.select{
				&:after{
					content:"";
					display: block;
					position: absolute;
					width: 94px;
					height: 8px;
					background: mdl.$rd;
					bottom: -4px;
					left: calc(50% - 47px);
				}
			}
			@include mdl.tab{
				font-size: 1.8rem;
				padding: 10px 15px 20px;
				&.select{
					&:after{
						width: 60px;
						left: calc(50% - 30px);
					}
				}
			}
		}
		&__content{
			width: 900px;
			max-width:90%;
			margin: 0 auto;
			display: none;
			&.show{
				display: block;
			}
			@include mdl.sp{
				width: 315px;
			}
		}
	}
	.anchor-list{
		width: 900px;
		padding-left: 20px;
		max-width:100%;
		margin: 49px auto 61px;
		display: flex;
		flex-wrap:wrap;
		&__item{
			width: 32%;
			margin: 0 2% 7px 0;
    	&:nth-of-type(3n){
    		margin-right: 0;
    	}
		}
		@include mdl.tab{
			justify-content: space-between;
			margin-bottom: 45px;
			padding-left: 0;
			&__item{
				width: 49%;
				margin-right: 0;
			}
		}
		@include mdl.sp{
			&__item{
				width: 100%;
			}
		}
	}
	section{
		word-break: break-word;
		&+section{
			margin-top: 100px;
			@include mdl.tab{
				margin-top: 82px;
			}
		}
		.medium{
			font-weight: mdl.$medium;
		}
		.fz18{
			font-size: 1.8rem;
			vertical-align:baseline;
		}
		.indent{
			padding-left: 1.5rem;
			text-indent: -1.5rem;
		}
		h2{
			&+p{
				margin-top: 27px;
			}
			&+h3{
				margin-top: 20px;
			}
			@include mdl.tab{
				&+p{
					margin-top: 22px;
				}
			}
		}
		h3{
			font-size: 2.1rem;
			font-weight: bold;
			letter-spacing: 0.1em;
			line-height: calc(31 / 21);
			padding-left: 30px;
			position: relative;
			&:before{
				content:"";
				display: block;
				position: absolute;
				left:0;
				top:5px;
				width: 17px;
				height: 22px;
				background: url(../img/theater/ic_kamen.svg) center /contain no-repeat;
			}
			&+p{
				margin-top: 10px;
			}
			&+ul{
				margin-top: 10px;
			}
			@include mdl.tab{
				font-size: 1.8rem;
				line-height: calc(27 / 18);
				padding-left: 27px;
				&:before{
					width: 15px;
					height: 20px;
				}
			}
		}
		p{
			font-size: 1.4rem;
			letter-spacing: 0.1em;
			line-height: calc(26 / 14);
			&+p{
				margin-top: 25px;
			}
			&+h3{
				margin-top: 47px;
			}
			&+ul{
				margin-top: 10px;
			}
			&+.time__row{
				margin-top: 25px;
			}
			@include mdl.tab{
				&+h3{
					margin-top: 37px;
				}
			}
		}
		a:not(.btn-arrow){
			color: #8A8A8A;
			text-decoration:underline;
			&.blank{
        &:after{
          content:url(../img/common/ic_blank_gy.svg);
          display: inline-block;
          padding-left: 5px;
        }
			}
		}
		.btn-arrow{
			width: 320px;
		}
		ul{
			border: solid 1px #D8D8D8;
			padding: 15px 20px 15px;
			text-align: left;
			font-size: 1.4rem;
			letter-spacing: 0.1em;
			line-height: 2;
			li{
				text-indent: -1.5rem;
				padding-left: 1.5rem;
				&.img-wrap{
					text-align: center;
					margin: 5px 0 10px;
				}
				a:not(.btn-arrow){
					&.blank{
						&:after{
							padding-left: 20px;
						}
					}
				}
			}
			&+h3{
				margin-top: 50px;
			}
			&+p{
				margin-top: 10px;
			}
			@include mdl.tab{
				&+h3{
					margin-top: 40px;
				}
			}
		}
		.scroll-box{
			margin-top: 30px;
			border: solid 1px #8A8A8A;
			height: 340px;
			overflow-y:scroll;
			padding: 19px 18px;
			@include mdl.tab{
				margin-top: 25px;
			}
		}
		.theater-360{
			margin-top: 45px;
			@include mdl.tab{
				margin-top: 37px;
			}
		}
		.theater-slider{
			margin-top: 12px;
			.slide{
				text-align: center;
			}
			a{
				display: inline-block;
				margin: 0 15px;
				@include mdl.tab{
					margin: 0 6px;
				}
			}
	  	.slick-prev,
	  	.slick-next{
	  		width: 50px;
	  		height: 50px;
	  		border: solid 1px #D8D8D8;
	  		background: #fff;
	  		border-radius: 50%;
	  		top:calc(50% + 8px);
	  		z-index: 99;
	  		transition: .3s ease;
	  		&:before{
	  			content:"";
	  			display: block;
	  			position: absolute;
	  			width: 11px;
	  			height: 11px;
	  			border-top: solid 2px mdl.$bl;
	  			border-right: solid 2px mdl.$bl;
	  			opacity: 1;
	  			top:50%;
	  			transition: .3s ease;
	  		}
	  		&:hover{
	  			background: #D8D8D8;
	  			&:before{
	  				border-color: #fff;
	  			}
	  		}
	  	}
	  	.slick-prev{
	  		left:20%;
	  		&:before{
	  			transform: translateY(-50%) rotate(-135deg);
	  			left:22px;
	  		}
	  	}
	  	.slick-next{
	  		right:20%;
	  		&:before{
	  			transform: translateY(-50%) rotate(45deg);
	  			right:22px;
	  		}
	  	}
	  	@include mdl.tab{
	  		.slick-prev,
	  		.slick-next{
	  			width: 30px;
	  			height: 30px;
	  			top:calc(50% - 7px);
	  			&:before{
	  				width: 6px;
	  				height: 6px;
	  			}
	  		}
	  		.slick-prev{
	  			left:3%;
	  			&:before{
	  				left:12px;
	  			}
	  		}
	  		.slick-next{
	  			right:3%;
	  			&:before{
	  				right:12px;
	  			}
	  		}
	  	}
	  	&+p{
	  		margin-top: 10px;
	  	}
		}
		.time{
			&__row{
				margin-top: 10px;
				display: flex;
				&+ul{
					margin-top: 8px;
				}
				&+h3{
					margin-top: 50px;
				}
			}
			&__box{
				width: 23%;
				margin-right: 2.66666%;
				&:nth-of-type(4n){
					margin-right: 0;
				}
				p{
					font-weight: mdl.$medium;
				}
			}
			&__ttl{
				text-align: center;
				border: solid 1px mdl.$bl;
				line-height: 1;
				padding: 4px 0;
				margin-bottom: 11px;
				&.bl{
					background: mdl.$bl;
					color: #fff;
				}
			}
			@include mdl.tab{
				&__row{
					display: block;
				}
				&__box{
					width: 100%;
					max-width:200px;
					margin-right: 0;
					white-space: nowrap;
					&+.time__box{
						margin-top: 26px;
					}
				}
			}
		}
		.enter{
			&__row{
				margin-top: 31px;
				display: flex;
				justify-content: space-between;
				&+p{
					margin-top: 10px;
				}
			}
			@include mdl.tab{
				&__row{
					display: block;
					text-align: center;
					margin-top: 24px;
				}
				&__box{
					&+.enter__box{
						margin-top: 13px;
					}
				}
			}
		}
		.map{
			width: 600px;
			max-width:100%;
			margin: 20px auto 0;
			.iframe-wrap{
				padding-bottom: 450px;
			}
			&+h3{
				margin-top: 60px;
			}
			@include mdl.tab{
				.iframe-wrap{
					padding-bottom: 300px;
				}
			}
		}
		.calendar{
			margin-top: 48px;
		}
		.performer{
			&__row{
				width: 650px;
				max-width:100%;
				margin: 0 auto;
				display: flex;
				justify-content: center;
				flex-wrap:wrap;
			}
			&__box{
				margin: 12px 6px 0;
			}
			@include mdl.tab{
				&__box{
					width: calc(46% - 12px);
					text-align: center;
					&.col1{
						width: calc(100% - 12px);
					}
					&.col2{
						width: calc(58% - 12px);
					}
				}
			}
		}
	}
}

/*　THEATER　ここまで　*/

/*　MUSIC　ここから　*/

.main--music{
	margin-bottom: 100px;
	@include mdl.tab{
		margin-bottom: 80px;
	}
	.under-ttl{
		margin-bottom: 63px;
		@include mdl.tab{
			margin-bottom: 53px;
		}
	}
	.anchor-list{
		width: 800px;
		max-width:100%;
		margin: 0 auto 54px;
		display: flex;
		flex-wrap:wrap;
		&__item{
			width: 32%;
			margin: 0 2% 7px 0;
    	&:nth-of-type(3n){
    		margin-right: 0;
    	}
		}
		@include mdl.tab{
			justify-content: space-between;
			margin-bottom: 45px;
			&__item{
				width: 49%;
				margin-right: 0;
			}
		}
		@include mdl.sp{
			&__item{
				width: 100%;
			}
		}
	}
	.music{
	  width: 1080px;
	  max-width: 90%;
	  margin: 0 auto;
	  @include mdl.sp{
	    width: 315px;
	  }
	  .group{
	  	&:nth-of-type(n+2){
	  		margin-top: 80px;
	  	}
	  	&__ttl{
	  		margin-bottom: 30px;
	  	}
	  	@include mdl.tab{
	  		&__ttl{
	  			margin-bottom: 25px;
	  		}
	  	}
	  }
    &__list{
    	display: flex;
    	flex-wrap:wrap;
    	margin: 0 -12px;
    	@include mdl.tab{
    		display: block;
    		margin: 0 -3px;
    	}
    }
    &__item{
    	width: 31%;
    	text-align: center;
    	margin: 0 3.5% 20px 0;
    	&:nth-of-type(3n){
    		margin-right: 0;
    	}
    	.img-wrap{
    		margin-bottom: 3px;
    		padding: 0 3px 6px;
    		img{
    			box-shadow: 0 3px 6px rgba(0,0,0,.16);
    		}
    	}
    	.ttl{
    		font-size: 1.6rem;
    		font-weight: mdl.$medium;
    		letter-spacing: 0.1em;
    		line-height: calc(24 / 16);
        &:after{
          content:url(../img/common/ic_blank_bl.svg);
          display: inline-block;
          padding-left: 5px;
        }
    	}
    	@include mdl.tab{
    		width: 100%;
    		margin: 0;
    		&:nth-of-type(n+2){
    			margin-top: 20px;
    		}
    		.img-wrap{
    			margin-bottom: 2px;
    		}
    		.ttl{
    			font-size: 1.4rem;
    			line-height: calc(20 / 14);
    		}
    	}
    }
    &__link{
    	display: block;
    	&:hover{
    		.ttl{
    			text-decoration:underline;
    		}
    	}
    }
	}
	.btn-row{
		margin-top: 40px;
		text-align: center;
		@include mdl.tab{
			margin-top: 59px;
		}
	}
}

/*　MUSIC　ここまで　*/


/*　SNS / MOVIE　ここから　*/

.main--sns_movie{
	margin-bottom: 120px;
	@include mdl.tab{
		margin-bottom: 80px;
	}
	.under-ttl{
		margin-bottom: 73px;
		@include mdl.tab{
			margin-bottom: 53px;
		}
	}
	.sns{
		width: 560px;
		max-width:90%;
		margin: 0 auto;
		display: flex;
		flex-wrap:wrap;
		&__box{
			@include mdl.pc{
				width: 80px;
				margin-right: calc((100% - 400px) / 4);
				&:nth-of-type(5n){
					margin-right: 0;
				}
				&:nth-of-type(n+6){
					margin-top: 40px;
				}
			}
			@include mdl.tab{
				width: 60px;
				margin-right: calc((100% - 240px) / 3);
				&:nth-of-type(4n){
					margin-right: 0;
				}
				&:nth-of-type(n+5){
					margin-top: 40px;
				}
			}
		}
	}
}

/*　SNS / MOVIE　ここまで　*/


/*　CONTACT　ここから　*/

.form-wrap{
	.confirm-txt{
		display: none;
	}
	.btn-row{
		margin-top: 40px;
		text-align: center;
		.btn-wrap{
			max-width:100%;
			display: inline-block;
		}
		.btn{
			display: block;
			width: 100%;
		  font-size: 1.6rem;
		  letter-spacing: 0.1em;
		  font-family: mdl.$noto;
		  font-weight: mdl.$medium;
		  color: #fff;
		  background: mdl.$rd;
		  border-radius:100px;
		  line-height: calc(22 / 16);
		  position: relative;
		  cursor: pointer;
		  transition:.3s ease;
		  width: 320px;
		  &:hover{
		    background: mdl.$sc;
		    padding-left: 30px;
		    .arrow{
		      right:10px;
		    }
		  }
		  &--back{
		  	background: #fff;
		  	border: solid 1px #8A8A8A;
		  	color: mdl.$bl;
		  	&:hover{
		  		background: #8A8A8A;
		  		color: #fff;
		  	}
		  }
		  @include mdl.tab{
		    width: 300px;
		  }
		}
	}
}

.main--contact{
	.under-ttl{
		margin-bottom: 75px;
		@include mdl.tab{
			margin-bottom: 54px;
		}
	}
	.form-wrap{
		width: 700px;
		max-width:90%;
		margin: 0 auto 100px;
		@include mdl.tab{
			margin-bottom: 80px;
		}
	  @include mdl.sp{
	    width: 315px;
	  }
		input,
		textarea,
		select{
			font-family: mdl.$noto;
			border: solid 1px #D8D8D8;
			box-sizing:border-box;
			padding-left: 1rem;
			outline:none;
			border-radius:8px;
			&::placeholder {
			  color: #C8C8C8;
				font-size: 1.4rem;
				font-weight: mdl.$light;
			}
		}
		input[type="text"],
		input[type="email"]{
			height: 50px;
			width: 100%;
		}
		textarea{
			width: 100%;
			height: 230px;
			padding: 1rem;
			resize: none;
		}
		.select-wrap{
			position: relative;
			width: 47%;
			&:before{
				content:"";
				display: block;
				position: absolute;
				right:14px;
				top:calc(50% - 6px);
				width: 6px;
				height: 6px;
				transform:rotate(45deg);
				border-bottom: solid 2px mdl.$bl;
				border-right: solid 2px mdl.$bl;
				pointer-events: none;
			}
			select{
				width: 100%;
				height: 50px;
				cursor: pointer;
				-webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
				-moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
				appearance: none; /* 標準のスタイルを無効にする */
				font-size: 1.5rem;
				font-weight: normal;
			}
			::-ms-expand {
				display: none;
			}
			@include mdl.tab{
				width: 100%;
			}
		}
		input[type="submit"],
		input[type="button"] {
		  border-radius: 0;
		  -webkit-box-sizing: content-box;
		  -webkit-appearance: button;
		  appearance: button;
		  border: none;
		  box-sizing: border-box;
		  &::-webkit-search-decoration {
		    display: none;
		  }
		  &::focus {
		    outline-offset: -2px;
		  }
		}
		.dl{
			&+.dl{
				margin-top: 42px;
			}
			.dt{
				padding-bottom: 12px;
				&__txt{
					font-size: 1.6rem;
					font-weight: medium;
					vertical-align:middle;
				}
				.required{
					display: inline-block;
					font-size: 1.2rem;
					color: #fff;
					text-align: center;
					padding: 4px 10px;
					margin-left: 8px;
					background: mdl.$rd;
					vertical-align:middle;
				}
			}
			.dd{
				display: flex;
				justify-content: space-between;
				flex-wrap:wrap;
			}
			.input-wrap{
				width: 100%;
				&--half{
					width: 48%;
				}
				&--row2{
					&+.input-wrap--row2{
						margin-top: 10px;
					}
				}
			}
			@include mdl.tab{
				&+.dl{
					margin-top: 42px;
				}
				.dt{
					padding-bottom: 14px;
				}
			}
		}
		.btn-row{
			margin-top: 30px;
			@include mdl.tab{
				margin-top: 30px;
			}
		}
	}
}

.main--confirm{
	.under-ttl{
		margin-bottom: 63px;
		@include mdl.tab{
			margin-bottom: 52px;
		}
	}
	.confirm{
		width: 900px;
		max-width:90%;
		margin: 0 auto 100px;
		@include mdl.tab{
			margin-bottom: 80px;
		}
	  @include mdl.sp{
	    width: 315px;
	  }
	  &__ttl{
	  	margin-bottom: 28px;
	  	@include mdl.tab{
	  		margin-bottom: 24px;
	  	}
	  }
	}
	.form-wrap{
	  .dl{
	  	display: flex;
	  	justify-content: space-between;
  		font-size: 1.6rem;
  		line-height: calc(28 / 16);
	  	&+.dl{
	  		margin-top: 26px;
	  	}
	  	.dt{
	  		width: 22%;
	  		&__txt{
	  			font-weight: bold;
	  		}
	  		.required{
	  			display: none;
	  		}
	  	}
	  	.dd{
	  		width: 78%;
	  	}
	  	.input-wrap--row2{
	  		&+.input-wrap--row2{
	  			display: none;
	  		}
	  	}
	  	@include mdl.tab{
	  		display: block;
	  		.dt{
	  			width: 100%;
	  			padding-bottom: 4px;
	  		}
	  		.dd{
	  			width: 100%;
	  		}
	  	}
	  }
	  .confirm-txt{
	  	display: block;
	  	text-align: center;
	  	font-size: 1.6rem;
	  	line-height: calc(28 / 16);
	  	margin: 47px 0;
	  }
	  .btn-row{
	  	margin-top: 0;
	  	.btn-wrap{
	  		width: 200px;
	  		margin: 0 20px;
	  		&--confirm{
	  			width: 0;
	  			margin: 0;
	  		}
	  	}
	  	.btn{
	  		padding: 19px 5px;
	  		width: 200px;
	  	}
	  	@include mdl.tab{
	  		display: flex;
	  		justify-content: center;
	  		.btn-wrap{
	  			margin: 0;
	  			max-width:47%;
	  			&--back{
	  				margin-right: 6%;
	  			}
	  		}
	  		.btn{
	  			width: 150px;
	  			max-width:100%;
	  		}
	  	}
	  }
	}
}

.main--completion{
	.under-ttl{
		margin-bottom: 63px;
		@include mdl.tab{
			margin-bottom: 52px;
		}
	}
	.completion{
		width: 900px;
		max-width:90%;
		margin: 0 auto 100px;
		@include mdl.tab{
			margin-bottom: 80px;
		}
	  @include mdl.sp{
	    width: 315px;
	  }
	  &__ttl{
	  	margin-bottom: 28px;
	  	@include mdl.tab{
	  		margin-bottom: 24px;
	  	}
	  }
	  &__txt-wrap{
	  	text-align: center;
	  	margin-bottom: 48px;
	  	.txt{
	  		font-size: 1.6rem;
	  		line-height: calc(28 / 16);
	  		&+.txt{
	  			margin-top: 28px;
	  		}
	  	}
	  	@include mdl.tab{
	  		margin-bottom: 38px;
	  	}
	  }
	  &__btn-row{
	  	text-align: center;
	  	.btn-arrow{
	  		width: 240px;
	  		font-family: mdl.$noto;
	  	}
	  	@include mdl.tab{
	  		.btn-arrow{
	  			width: 210px;
	  		}
	  	}
	  }
	}
}

/*　CONTACT　ここまで　*/

